export enum MoleculorSocketEndpointKey {
  RATE_MESSAGE = 'assistant.rate-message',
}

export type MoleculorSocketEndpointPayload = {
  [MoleculorSocketEndpointKey.RATE_MESSAGE]: {
    messageId: string;
    useful: boolean;
    comment?: string;
  };
};
