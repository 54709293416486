import { MoleculorSocketEndpointKey, MoleculorSocketEndpointPayload } from './endpoints';
import { SocketError } from './error';
import { useSocket } from './SocketProvider';

export const useMoleculorSocket = () => {
  const { socket } = useSocket();

  return {
    socket: {
      emitWithAck: async <K extends MoleculorSocketEndpointKey>(
        key: K,
        payload: MoleculorSocketEndpointPayload[K],
      ) => {
        if (!socket) {
          return;
        }

        const response = await socket.emitWithAck('call', key, payload);

        if (response?.code) {
          throw new SocketError(response);
        }

        return response;
      },
    },
  };
};
