type SocketErrorProps = {
  type: string;
  code: number;
  message: string;
};

export class SocketError extends Error {
  type: string;

  code: number;

  constructor({ type, code, message }: SocketErrorProps) {
    super(message);
    this.type = type;
    this.code = code;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
